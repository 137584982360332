<template>
  <div class="Integralrule">
      <p>1.志愿者参与县新时代文明实践中心（所、站、点）组织或认定的志愿服务活动，均可获得相应积分（1小时按20积分计算）。</p>
      <p>2.志愿者完成签到和文章阅读每天可获得5积分（签到得1分；每阅读一篇文章超过5秒得1分，每天最高不超过4分）。</p>
      <p>3.积分由所在乡镇（单位）核定兑换 。</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
  .Integralrule{
    width: 100%;
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    font-size: 16px;
    text-indent: 32px;
    line-height: 30px;
    text-align: justify;
  }
</style>
